const create_ticket_router_path = {
    name: 'newSupportRequest',
    query: {
        creationPoint: "communications",
    },
};
export const solution_data = {
    fine: {
        'ToFaqByUin': {
            title: 'Как найти информацию по штрафу в разделе "Вопросы и ответы"?'
        },
        'FineTroubleReport': {
            title: 'Сообщить о проблеме'
        },
        1: {
            title: 'Неверная точка на карте'
        },
        2: {
            title: 'Постановление не погашено'
        },
        3: {
            title: 'Не согласен с ИП ФССП'
        },
        4: {},
        5: {
            title: 'Не указан адрес нарушения'
        },
        6: {
            title: 'Неверная дата нарушения или ее нет'
        },
        7: {
            title: 'Нет фото ГИБДД (УИН начинается на 188)'
        },
        8: {
            title: 'Неверная статья нарушения или ее нет'
        },
        9: {
            title: 'Нет фото Комитета по транспорту г. Санкт-Петербург'
        },
        10: {
            title: 'Нет фото ГКУ АМПП (УИН начинается на 0355)'
        },
        11: {
            title: 'Нет фото МАДИ (УИН начинается на 0356)'
        },
        12: {
            title: 'Нет фото. Ространснадзор. Легковой автомобиль'
        },
        13: {
            title: 'Нет фото. Ространснадзор. Крупно-тоннажный транспорт'
        },
        14: {
            title: 'Оплачен штраф вместо ИП ФССП'
        },
        15: {
            title: 'Штраф оплачен в другом сервисе'
        },
        16: {
            title: 'Узнать подробности об ИП ФССП'
        },
        17: {
            title: 'Изменить статус задолженности в приложении'
        },
        18: {
            title: 'Сроки погашения ИП ФССП'
        },
        19: {
            title: 'Рекомендуем оплатить задолженность'
        },
        20: {
            title: 'Появилось доначисление по ИП ФССП'
        },
        21: {
            title: 'Какие штрафы нельзя оплатить со скидкой?'
        },
        22: {
            title: 'Проверьте добавленные документы'
        },
        'ToUinPicker': {
            title: 'Оплатить по номеру постановления (УИН)'
        },
        56: {
            title: 'Штраф успешно оплачен в нашем сервисе. Ожидайте погашение'
        },
        'HasNoPhotos': {
            title: 'Фотографий нет',
            internal_path: create_ticket_router_path,
            data: { page: 'CreateTicket' },
        },
        '30DaysHasPassed': {
            title: 'Прошло 30 дней с момента оплаты',
            internal_path: create_ticket_router_path,
            data: { page: 'CreateTicket' },
        },
        'HasNoMyQuestion': {
            title: 'Нет моего вопроса',
            internal_path: create_ticket_router_path,
            data: { page: 'CreateTicket' },
        },
        'HasNoUIN': {
            title: 'Нет номера постановления',
            internal_path: create_ticket_router_path,
            data: { page: 'CreateTicket' },
        },
        'NotAgreeWithFineOrder': {
            title: 'Не согласен с ИП ФССП',
            internal_path: create_ticket_router_path,
            data: { page: 'CreateTicket' },
        },
        'DataTransferOtherDevices': {
            title: 'Перенос данных между другими устройствами',
            internal_path: create_ticket_router_path,
            data: { page: 'CreateTicket' },
        },
        57: {
            title: 'ИП ФССП успешно оплачено в нашем сервисе. Ожидайте погашение'
        },
        23: {
            title: 'На фотографии чужой автомобиль'
        },
        24.1: {
            title: 'Как обжаловать штраф ГИБДД?'
        },
        24.2: {
            title: 'Как обжаловать штраф ГИБДД?'
        },
        25.1: {
            title: 'Как обжаловать штраф ГКУ АМПП?'
        },
        25.2: {
            title: 'Как обжаловать штраф ГКУ АМПП?'
        },
        26.1: {
            title: 'Как обжаловать штраф МАДИ?'
        },
        26.2: {
            title: 'Как обжаловать штраф МАДИ?'
        },
        27.1: {
            title: 'Как обжаловать штраф Комитета по транспорту  г. Санкт-Петербург?'
        },
        27.2: {
            title: 'Как обжаловать штраф Комитета по транспорту  г. Санкт-Петербург?'
        },
        28.1: {
            title: 'Как получить информацию о проезде по ЦКАД?'
        },
        28.2: {
            title: 'Как получить информацию о проезде по ЦКАД?'
        },
        29.1: {
            title: 'Как получить информацию о штрафе системы Платон?'
        },
        29.2: {
            title: 'Как получить информацию о штрафе системы Платон?'
        },
        30: {
            title: 'Проверка штрафов по иностранному автомобилю'
        },
        31: {
            title: 'Проверка штрафов по иностранному водительскому удостоверению'
        },
        32: {
            title: 'Проверить ограничения на автомобиль'
        },
        33: {
            title: 'Проверить водительское удостоверение'
        },
        34: {
            title: 'Автомобиль продан, но прежнему владельцу приходят штрафы'
        },
        35: {
            title: 'Как удалить штрафы из истории?'
        },
        36: {
            title: 'Как удалить автомобиль?'
        },
        37: {
            title: 'Перенос данных между устройствами Android'
        },
        41: {
            title: 'Обжаловать штраф за парковку в г.Санкт-Петербург (Комитет по транспорту)'
        },
        42: {
            title: 'Обжаловать штраф за парковку во дворе в г.Санкт-Петербург (ГАТИ)'
        },
        43: {
            title: 'Не оплачена парковка в Казани'
        },
        44: {
            title: 'Не оплачена парковка в Белгороде'
        },
        45: {
            title: 'Не оплачена парковка в Твери'
        },
        46: {
            title: 'Не оплачена парковка в Калуге'
        },
        47: {
            title: 'Не оплачена парковка в Туле'
        },
        48: {
            title: 'Не оплачена парковка в Ижевске (Удмуртия)'
        },
        49: {
            title: 'Не оплачена парковка в Перми'
        },
        50: {
            title: 'Не оплачена парковка в Воронеже'
        },
        51: {
            title: 'Не оплачена парковка в Тюмени'
        },
        52: {
            title: 'Не оплачена парковка в Красноярске'
        },
        53: {
            title: 'Не оплачена парковка в Ростове-На-Дону'
        },
        54: {
            title: 'Не оплачена парковка в Екатеринбурге'
        },
        55: {
            title: 'Не оплачена парковка в Курске'
        },
        58: {
            title: 'Другой вопрос'
        },
        59: {
            title: 'Другой вопрос'
        },
    },
    tax: {
        1: {
            title: 'Как узнать, положены ли мне льготы по налогам?'
        },
        2: {
            title: 'Налоговые льготы для многодетных семей'
        },
        3: {
            title: 'Налоговые льготы для предпенсионеров'
        },
        4: {
            title: 'Льготы для пенсионеров по налогу на имущество'
        },
        5: {
            title: 'Льготы для пенсионеров по транспортному налогу'
        },
        6: {
            title: 'Льготы для пенсионеров по земельному налогу'
        },
        7: {
            title: 'Льготы для ветеранов боевых действий по налогу на имущество'
        },
        8: {
            title: 'Льготы для ветеранов боевых действий по транспортному налогу'
        },
        9: {
            title: 'Льготы для ветеранов боевых действий по земельному налогу'
        },
        10: {
            title: 'Льготы для инвалидов по налогу на имущество'
        },
        11: {
            title: 'Льготы для инвалидов по транспортному налогу'
        },
        12: {
            title: 'Льготы для инвалидов по земельному налогу'
        },
        13: {
            title: 'Налоги для мобилизованных на СВО'
        },
        14: {
            title: 'Не приходит налог. Где взять квитанцию?'
        },
        15: {
            title: 'За что начислен налог и что такое ЕНП?'
        },
        16: {
            title: 'Почему реквизиты г. Тула?'
        },
        17: {
            title: 'Что такое сальдо ЕНС?'
        },
        18: {
            title: 'Не согласен с задолженностью по ЕНП'
        },
        19: {
            title: 'Как оплатить налог за другого человека?'
        },
        20: {
            title: 'Как оплатить налог частями?'
        },
        'DocumentsCheck': {
            title: 'Проверка добавленных документов'
        },
        'TaxTroubleReport': {
            title: 'Сообщить о проблеме'
        },
        22: {
            title: 'В какой срок погашается налог и как изменить статус налога в приложении?'
        },
        22.1: {
            title: 'Сообщить о проблеме'
        },
        23: {
            title: 'В какой срок погашается налог?'
        },
        23.1: {
            title: 'В какой срок погашается налог?'
        },
        24: {
            title: 'Причины увеличения суммы долга по ЕНП'
        },
        24.1: {
            title: 'Сообщить о проблеме'
        },
        25: {
            title: 'Почему начисляются пени?'
        },
        26: {
            title: 'За что начислены налоговые задолженности?'
        },
        28: {
            title: 'Почему нет налогов за 2023 год?'
        },
        29: {
            title: 'Как получить ИНН впервые?'
        },
        30: {
            title: 'Как узнать ИНН?'
        },
        31: {
            title: 'Как добавить ИНН?'
        },
        32: {
            title: 'Как удалить ИНН?'
        },
        33: {
            title: 'Налоговые вычеты'
        },
        34: {
            title: 'Как получить налоговый вычет на ребенка?'
        },
        35: {
            title: 'Как получить налоговый вычет по расходам на недвижимость?'
        },
        36: {
            title: 'Налоговая декларация о доходах 3-НДФЛ'
        },
        37: {
            title: 'Как получить налоговый вычет по расходам на обучение?'
        },
        38: {
            title: 'Как получить налоговый вычет льготнику?'
        },
        39: {
            title: 'Как получить налоговый вычет по расходам на лечение?'
        },
        40: {
            title: 'Как получить патент впервые?'
        },
        41: {
            title: 'Как оплатить патент на работу?'
        },
        42: {
            title: 'Как проверить подлинность патента?'
        },
        43: {
            title: 'Как продлить патент на работу?'
        },
        44: {
            title: 'Как связаться с ФНС по вопросам своих налогов?'
        },
        'FaqInfo': {
            title: 'Мы подготовили для вас информационный раздел "Вопросы и ответы"'
        },
        'PenaltiesIsPaid': {
            title: 'Пени оплачены',
            internal_path: create_ticket_router_path,
            data: { page: 'CreateTicket' },
        },
        'OtherQuestions': {
            title: 'Другие вопросы',
            internal_path: create_ticket_router_path,
            data: { page: 'CreateTicket' },
        },
    },
    ins: {
        '3HoursAfterPaymentIsPassed': {
            title: 'Прошло 3 часа после оплаты',
            internal_path: create_ticket_router_path,
            data: { page: 'CreateTicket' },
        },
        'UnableToGetPolicy': {
            title: 'Не получается оформить полис',
            internal_path: create_ticket_router_path,
            data: { page: 'CreateTicket' },
        },
        1: {
            title: 'Когда придет полис?'
        },
        3: {
            title: 'Уже есть полис, но приходят уведомления о продлении'
        },
        4: {
            title: 'Управление уведомлениями'
        },
        5: {
            title: 'Как внести изменения в полис?'
        },
        6: {
            title: 'Как добавить водителя?'
        },
        7: {
            title: 'Как оформить полис?'
        },
        8: {
            title: 'Нет предложений от страховых компаний'
        },
        9: {
            title: 'Как проверить подлинность полиса?'
        },
        10: {
            title: 'Как добавить актуальный полис в приложение?'
        },
        11: {
            title: 'КАСКО от бесполисных'
        },
        12: {
            title: 'Как расторгнуть договор ОСАГО?'
        },
        13: {
            title: 'Что влияет на стоимость полиса?'
        },
        14: {
            title: 'Что влияет на КБМ (коэффициент бонус-малус)?'
        },
        15: {
            title: 'В каком виде я получу полис ОСАГО после покупки?'
        },
        16: {
            title: 'ОСАГО на иностранное водительское удостоверение или автомобиль с иностранным госномером'
        },
        'FaqInfo': {
            title: 'Мы подготовили для вас информационный раздел "Вопросы и ответы"'
        },
    },
    common: {
        1: {
            title: 'Как добавить еще один документ?'
        },
        2: {
            title: 'Как удалить документ?'
        },
        3: {
            title: 'Как добавить ИНН?'
        },
        4: {
            title: 'Как удалить ИНН?'
        },
        5: {
            title: 'Как добавить полис ОСАГО в приложение?'
        },
        6: {
            title: 'Общая информация о сервисе'
        },
        7: {
            title: 'Чем может быть полезно приложение и сервис?'
        },
        8: {
            title: 'Безопасность платежей и персональных данных'
        },
        12: {
            title: 'Перенос данных между устройствами Android'
        },
        'DataTransferOtherDevices': {
            title: 'Перенос данных между другими устройствами',
            internal_path: create_ticket_router_path,
            data: { page: 'CreateTicket' },
        },
        'NotMyEmails': {
            title: 'Приходят письма не по моим данным',
            internal_path: create_ticket_router_path,
            data: { page: 'CreateTicket' },
        },
        15: {
            title: 'Push-уведомления'
        },
        14: {
            title: 'Email-уведомления'
        },
        13: {
            title: 'Уведомления по ОСАГО'
        },
        17: {
            title: 'Какая комиссия в сервисе?'
        },
        18: {
            title: 'За что взимается комиссия?'
        },
        19: {
            title: 'Бонусная система'
        },
        'FaqInfo': {
            title: 'Мы подготовили для вас раздел "Вопросы и ответы"'
        },
        20: {
            title: 'Юридическая помощь в обжаловании штрафов'
        },
    },
    marketing: {
        'mandarin': {
            title: 'Мандариновый патруль',
            internal_link: 'https://mandarin.rosfines.ru',
        },
        'fueldraw2': {
            title: 'Ноябрьская акция РосШтрафы',
            internal_link: 'https://promo.rosfines.ru/fueldraw2',
        },
        'promo_osago25': {
            title: 'ОСАГО Кэшбек № 4',
            internal_link: 'https://promo.rosfines.ru/promo_osago25',
        },
        'taxescashback': {
            title: 'Оплати налоги',
            internal_link: 'https://promo.rosfines.ru/taxescashback',
        },
        'OtherSubscriptionProblem': {
            title: 'Другая проблема по подписке, услуге, акции',
            internal_path: create_ticket_router_path,
            data: { page: 'CreateTicket' },
        },
        1: {
            title: 'Обжалование штрафов',
        },
        2: {
            title: 'Отключить рассылку по почте',
        },
        3: {
            title: 'Отключить пуши',
        },
    },
};